import React, { useEffect, useState } from 'react';
import { fetchLanguages } from '../services/api';

const LanguageSelector = ({ onSelectLanguage, defaultLanguage, selectedLanguage, filterByRegion }) => {
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        // Dohvati sve jezike iz baze
        fetchLanguages()
            .then(response => {
                const allLanguages = response.data.euLanguages.concat(response.data.nonEuLanguages);
                
                // Filtriraj jezike prema regiji, ako je specificirano
                const filteredLanguages = filterByRegion 
                    ? allLanguages.filter(lang => lang.region === filterByRegion) 
                    : allLanguages;
                
                setLanguages(filteredLanguages);
            })
            .catch(err => console.error(err));

        // Postavi početni odabrani jezik nakon dohvaćanja jezika
        if (defaultLanguage) {
            onSelectLanguage(defaultLanguage);
        }
    }, [filterByRegion, defaultLanguage, onSelectLanguage]);

    return (
        <select value={selectedLanguage} onChange={(e) => onSelectLanguage(e.target.value)}>
            {languages.map(lang => (
                <option key={lang.language_code} value={lang.language_code}>
                    {lang.language_name}
                </option>
            ))}
        </select>
    );
};

export default LanguageSelector;
