import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './components/Homepage';
import MainPage from './components/MainPage';
import Dictionary from './components/Dictionary';
import Categories from './components/Categories';
import Examples from './components/Examples';
import Scenarios from './components/scenarios';
import Jobs from './components/Jobs';
import SpecializedDictionary from './components/SpecializedDictionary';
import SpecializedScenarios from './components/SpecializedScenarios';
import GeneralLanguage from './components/GeneralLanguage';  // Uvezi komponentu GeneralLanguage
import ProfessionalLanguage from './components/ProfessionalLanguage';  // Uvezi komponentu ProfessionalLanguage
import Wordsexample from './components/Wordsexample'; // Uvezi Wordsexample komponentu
import CategoryCards from './components/CategoryCards';
import ScenarioCards from './components/ScenarioCards'; 
import VerbTenses from './components/VerbTenses';  // Dodaj ovu liniju za VerbTenses komponentu
import Testjobs from './components/Testjobs'; // Dodaj ovu liniju za uvoz komponente





import { LanguageProvider } from './context/LanguageContext';

function App() {
    return (
        <LanguageProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/dictionary" element={<Dictionary />} />
                    <Route path="/categories" element={<Categories />} />
                    <Route path="/examples" element={<Examples />} />
                    <Route path="/scenarios" element={<Scenarios />} />
                    <Route path="/jobs" element={<Jobs />} />
                    <Route path="/jobs/specialized-dictionary/:jobId" element={<SpecializedDictionary />} />
                    <Route path="/jobs/specialized-scenarios/:jobId" element={<SpecializedScenarios />} />
                    <Route path="/main-page" element={<MainPage />} />  {/* Zadrži samo ovu rutu */}
                    <Route path="/general-language" element={<GeneralLanguage />} />
                    <Route path="/professional-language" element={<ProfessionalLanguage />} />
                    <Route path="/wordsexample" element={<Wordsexample />} />
                    <Route path="/category-cards" element={<CategoryCards />} />
                    <Route path="/scenario-cards" element={<ScenarioCards />} />
                    <Route path="/verb-tenses" element={<VerbTenses />} />  {/* Ovdje dodajemo rutu za VerbTenses */}
                    <Route path="/test-jobs" element={<Testjobs />} />


                </Routes>
            </Router>
        </LanguageProvider>
    );
}

export default App;
