import React, { useEffect, useState, useContext } from 'react';
import { fetchDictionary, fetchVerbConjugations } from '../services/api';  // Import API functions
import { LanguageContext } from '../context/LanguageContext';
import styles from './VerbTenses.module.css';  // Import CSS module
import { speakText } from '../utils/speechUtils';  // Importiraj funkciju za govor

const VerbTenses = () => {
    const { euLanguage, nonEuLanguage, croatianLanguage = 'hr' } = useContext(LanguageContext);
    const [verbs, setVerbs] = useState([]); // State for verbs
    const [conjugations, setConjugations] = useState([]); // State for conjugations
    const [activeVerb, setActiveVerb] = useState(null); // Active verb
    const [isLoading, setIsLoading] = useState(true); // Loading state

    // Fetch verbs on component mount
    useEffect(() => {
        fetchDictionary(croatianLanguage, euLanguage, nonEuLanguage, 'VERB')  // Fetch only verbs
            .then(response => {
                setVerbs(response);
                if (response.length > 0) {
                    setActiveVerb(response[0]); // Automatically set the first verb as active
                }
                setIsLoading(false);
            })
            .catch(err => console.error('Error fetching verbs:', err));
    }, [croatianLanguage, euLanguage, nonEuLanguage]);

    // Fetch conjugations when activeVerb changes
    useEffect(() => {
        if (activeVerb) {
            fetchVerbConjugations(activeVerb.id)
                .then(response => setConjugations(response.data))
                .catch(err => console.error('Error fetching conjugations:', err));
        }
    }, [activeVerb]);

    const handleVerbClick = (verb) => {
        setActiveVerb(verb);
    };

    return (
        <div className={styles.verbTensesContainer}>
            <h1 className={styles.verbTensesTitle}>Verb Tenses</h1>

            <div className={styles.verbTableContainer}>
                {isLoading ? (
                    <p>Loading verbs...</p>
                ) : (
                    <table className={styles.verbTable}>
                        <thead>
                            <tr>
                                <th>HR</th>
                                <th>{euLanguage.toUpperCase()}</th>
                                <th>{nonEuLanguage.toUpperCase()}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {verbs.map((verb, index) => (
                                <tr
                                    key={index}
                                    onClick={() => handleVerbClick(verb)}  // Klik postavlja aktivni glagol
                                    className={verb === activeVerb ? styles.activeRow : ''}  // Dodaj klasu za aktivni red
                                >
                                    <td onClick={() => speakText(verb.word_hr, 'hr')}>
                                        {verb.word_hr}
                                    </td>
                                    <td onClick={() => speakText(verb.translations?.find(t => t.language_code === euLanguage)?.translated_word, euLanguage)}>
                                        {verb.translations?.find(t => t.language_code === euLanguage)?.translated_word || 'N/A'}
                                    </td>
                                    <td onClick={() => speakText(verb.translations?.find(t => t.language_code === nonEuLanguage)?.translated_word, nonEuLanguage)}>
                                    {verb.translations?.find(t => t.language_code === nonEuLanguage)?.translated_word || 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>

            {activeVerb && (
                <div className={styles.conjugationSection}>
                    <h2>{activeVerb.word_hr}</h2>
                    <h3>Conjugations</h3>
                    <table className={styles.conjugationTable}>
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>HR</th>
                                <th>{euLanguage.toUpperCase()}</th>  
                                <th>{nonEuLanguage.toUpperCase()}</th>  
                            </tr>
                        </thead>
                        <tbody>
  {conjugations.map((conjugation, index) => (
    <tr key={index}>
      <td>{conjugation.category}</td>
      
      {/* HR prijevod */}
      <td onClick={() => speakText(conjugation.translation_hr, 'hr')}>
        {conjugation.translation_hr || 'N/A'}
      </td>
      
      {/* EU prijevod */}
      <td onClick={() => speakText(conjugation.translation_en, euLanguage)}>
        {conjugation.translation_en || 'N/A'}
      </td>
      
      {/* Non-EU prijevod */}
      <td onClick={() => speakText(conjugation.translation_ar, nonEuLanguage)}>
        {conjugation.translation_ar || 'N/A'}
      </td>
    </tr>
  ))}
</tbody>

                    </table>
                </div>
            )}
        </div>
    );
};

export default VerbTenses;
