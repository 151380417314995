import React from 'react';
import './DictionaryModal.css';  // Stilovi za modal

const DictionaryModal = ({ word, closeModal, croatianLanguage, euLanguage, nonEuLanguage }) => {
  // Lista jezika koja se može proširiti (ako želiš dodati više jezika)
  const languages = [
    { code: croatianLanguage, label: 'Croatian' },
    { code: euLanguage, label: euLanguage.toUpperCase() },
    { code: nonEuLanguage, label: nonEuLanguage.toUpperCase() }
  ];

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-modal" onClick={closeModal}>X</button>
        
        <h2>{word[`word_${croatianLanguage}`]}</h2>
        
        {/* Prikaz slike s fallback opcijom */}
        <img
           src={`https://learning.eutecs.com/images/dictionary/${word.word_en}.webp`} 
          alt={word[`word_${croatianLanguage}`]}
          className="modal-image"
        />

        {/* Tablica s prijevodima riječi */}
        <table className="word-details-table">
          <thead>
            <tr>
              <th>Language</th>
              <th>Translation</th>
            </tr>
          </thead>
          <tbody>
            {languages.map((lang, index) => (
              <tr key={index}>
                <td>{lang.label}</td>
                <td>{word[`word_${lang.code}`] || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DictionaryModal;
