import React, { useEffect, useState, useContext, useMemo } from 'react';
import { fetchDictionary, fetchCategories } from '../services/api'; // API za dohvaćanje rječnika i kategorija
import { LanguageContext } from '../context/LanguageContext'; // Dohvati jezike iz konteksta
import styles from './Dictionary.module.css'; // Uvezi modularni CSS
import { speakText } from '../utils/speechUtils';

const Dictionary = () => {
    const { euLanguage, nonEuLanguage, croatianLanguage = 'hr' } = useContext(LanguageContext);
    const [dictionary, setDictionary] = useState([]);  // Inicijalna vrijednost prazan niz
    const [categories, setCategories] = useState([]);  // Stanje za kategorije
    const [selectedCategory, setSelectedCategory] = useState('All');  // Stanje za filtriranje po tipu riječi
    const [wordType, setWordType] = useState('All');  // Trenutni wordType za filtriranje
    const [loading, setLoading] = useState(true);  // Stanje za prikaz učitavanja
    const [hoveredWord, setHoveredWord] = useState(null);  // Praćenje koja je riječ pod mišem

    const wordTypeToCategory = useMemo(() => ({
        NOUN: 'Nouns',
        PRONOUN: 'Pronouns',
        ADVERB: 'Adverbs',
        ADJECTIVE: 'Adjectives',
        VERB: 'Verbs',
        PREPOSITION: 'Prepositions',
        CONJUNCTION: 'Conjunctions',
        INTERJECTION: 'Interjections',
        NUMERAL: 'Numerals'
    }), []); // Prazni niz znači da se ovaj objekt memoizira samo jednom i ne mijenja se
    

    useEffect(() => {
        // Dodajemo className na body za Dictionary stranicu
        document.body.classList.add('dictionary-page');
        
        // Uklanjanje className-a kad se komponenta demontira
        return () => {
            document.body.classList.remove('dictionary-page');
        };
    }, []);

    // Funkcija za dohvaćanje rječnika kad se promijeni tip riječi ili prilikom inicijalnog učitavanja
    useEffect(() => {
        setLoading(true);  // Postavi loading na true prilikom učitavanja
        fetchDictionary(croatianLanguage, euLanguage, nonEuLanguage, wordType)
            .then(response => {
                setDictionary(response);  // Postavi dohvaćeni rječnik
                setLoading(false);  // Ukloni stanje učitavanja kad podaci stignu
            })
            .catch(err => {
                console.error(err);
                setLoading(false);  // Ukloni stanje učitavanja čak i kod greške
            });
    }, [croatianLanguage, euLanguage, nonEuLanguage, wordType]);

    // Dohvati sve kategorije
    useEffect(() => {
        fetchCategories()
            .then(response => {
                // Filtriraj kategorije koje odgovaraju wordType mapiranju
                const filteredCategories = response.data.filter(cat => 
                    Object.values(wordTypeToCategory).includes(cat.category_en)
                );
                setCategories(filteredCategories);  // Postavi filtrirane kategorije
            })
            .catch(err => console.error(err));
    }, [wordTypeToCategory]);

    // Funkcija koja se izvršava kad se promijeni odabrana kategorija
    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setSelectedCategory(selectedCategory);

        // Pronađi odgovarajući word_type iz mape (ako postoji)
        const foundWordType = Object.keys(wordTypeToCategory).find(key => wordTypeToCategory[key] === selectedCategory);
        
        // Ažuriraj `wordType` na temelju odabrane kategorije ili postavi 'All'
        setWordType(foundWordType || 'All');  
    };

    const handleMouseEnter = (word, language) => {
        setHoveredWord({ word, language });
    };

    const handleMouseLeave = () => {
        setHoveredWord(null);
    };

    const handleSpeak = () => {
        if (hoveredWord) {
            speakText(hoveredWord.word, hoveredWord.language);
        }
    };

    return (
        <div className={styles.dictionaryContainer}>
            <div className={styles.imageContainer}>
                <img src="/images/8.webp" alt="Dictionary" className={styles.dictionaryImage} />
                <h1 className={styles.dictionaryTitle}>Dictionary</h1>
            </div>

            <div className={styles.filterContainer}>
                <label htmlFor="wordType" className={styles.filterLabel}>Select Word Type:</label>
                
                <select 
                    id="wordType" 
                    className={styles.filterSelect} 
                    value={selectedCategory} 
                    onChange={handleCategoryChange}  // Koristi ispravnu funkciju
                >
                    <option value="All">All</option>
                    {categories.map((cat, index) => (
                        <option key={index} value={cat.category_en}>
                            {cat[`category_${croatianLanguage}`]} / {cat[`category_${euLanguage}`]} / {cat[`category_${nonEuLanguage}`]}
                        </option>
                    ))}
                </select>
            </div>

            <div className={styles.tableWrapper}>
                <table className={styles.dictionaryTable}>
                    <thead>
                        <tr>
                            <th>HR</th>
                            <th>{euLanguage.toUpperCase()}</th>
                            <th>{nonEuLanguage.toUpperCase()}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr><td colSpan="3">Loading...</td></tr>
                        ) : dictionary.length > 0 ? (
                            dictionary.map((word, index) => (
                                <tr key={index} className={styles.dictionaryRow}>
                                {/* Izgovor za hrvatski jezik */}
                                <td
                                  onMouseEnter={() => handleMouseEnter(word.word_hr, 'hr')}
                                  onMouseLeave={handleMouseLeave}
                                  onClick={handleSpeak}
                                >
                                  {word.word_hr || 'N/A'}
                                </td>
                              
                                {/* Izgovor za EU jezik */}
                                <td
                                  onMouseEnter={() => handleMouseEnter(word.translations.find(t => t.language_code === euLanguage)?.translated_word, euLanguage)}
                                  onMouseLeave={handleMouseLeave}
                                  onClick={handleSpeak}
                                >
                                  {word.translations.find(t => t.language_code === euLanguage)?.translated_word || 'N/A'}
                                </td>
                              
                                {/* Izgovor za non-EU jezik */}
                                <td
                                  onMouseEnter={() => handleMouseEnter(word.translations.find(t => t.language_code === nonEuLanguage)?.translated_word, nonEuLanguage)}
                                  onMouseLeave={handleMouseLeave}
                                  onClick={handleSpeak}
                                >
                                  {word.translations.find(t => t.language_code === nonEuLanguage)?.translated_word || 'N/A'}
                                </td>
                              </tr>
                              
                            ))
                        ) : (
                            <tr><td colSpan="3" className={styles.noWords}>No words found.</td></tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Dictionary;
