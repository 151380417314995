import React, { useEffect, useState, useContext } from 'react';
import { fetchExamples, fetchCategories } from '../services/api';  // Dodaj API za kategorije i primjere
import { LanguageContext } from '../context/LanguageContext'; // Dohvati jezike iz konteksta
import './Examples.css';  // Uvezi tvoj CSS

const Examples = () => {
    const { euLanguage, nonEuLanguage, croatianLanguage = 'hr' } = useContext(LanguageContext);  // Dohvati jezike iz konteksta
    const [examples, setExamples] = useState([]);
    const [categories, setCategories] = useState([]);  // Dodaj stanje za kategorije
    const [category, setCategory] = useState('');  // Kategorija za filtriranje

    // Dohvati primjere kada se promijeni kategorija
    useEffect(() => {
        fetchExamples(category)  // API poziv s odabranom kategorijom
            .then(response => {
                setExamples(response.data);  // Postavi dohvaćene primjere
            })
            .catch(err => console.error(err));
    }, [category]);
    
    // Dohvati kategorije tipa 'example'
    useEffect(() => {
        fetchCategories()  // Dohvati sve kategorije
            .then(response => {
                // Filtriraj samo one kategorije koje imaju 'category_type' kao 'example'
                const filteredCategories = response.data.filter(cat => cat.category_type === 'example');
                setCategories(filteredCategories);  // Postavi samo example kategorije
            })
            .catch(err => console.error(err));
    }, []);
    
    return (
        <div className="examples-container">
            <h1 className="examples-title">Examples</h1> {/* Dodaj klasu za h1 */}

            {/* Padajući izbornik za filtriranje kategorija */}
            <div className="filter-container"> {/* Dodaj div za filter */}
                <label htmlFor="category" className="filter-label">Filter by Category:</label>
                <select id="category" className="filter-select" onChange={(e) => setCategory(e.target.value)}>
                    <option value="">All Categories</option>
                    {categories.map((cat, index) => (
                        <option key={index} value={cat.category_en}> {/* Koristi category_en kao value */}
                            {cat[`category_${croatianLanguage}`]} / {cat[`category_${euLanguage}`]} / {cat[`category_${nonEuLanguage}`]}
                        </option>
                    ))}
                </select>
            </div>

            {/* Prikaz tablice primjera */}
            <table className="examples-table">
                <thead>
                    <tr className="examples-table-header">
                        <th className="table-header">Example ({croatianLanguage.toUpperCase()})</th>
                        <th className="table-header">Example ({euLanguage.toUpperCase()})</th>
                        <th className="table-header">Example ({nonEuLanguage.toUpperCase()})</th>
                    </tr>
                </thead>
                <tbody className="examples-table-body">
                    {examples.length > 0 ? (  // Prikaži podatke samo ako ih ima
                        examples.map((example, index) => (
                            <tr key={index} className="examples-row">
                                <td className="example-cell">{example[`example_${croatianLanguage}`]}</td>
                                <td className="example-cell">{example[`example_${euLanguage}`] || 'N/A'}</td>
                                <td className="example-cell">{example[`example_${nonEuLanguage}`] || 'N/A'}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3" className="no-examples">No examples found for the selected category.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Examples;
