import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './GeneralLanguage.module.css';  // Uvezi CSS modul

const GeneralLanguage = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.generalLanguageContainer}>
            <h2 className={styles.pageTitle}>General Language Learning</h2>
            <p className={styles.pageDescription}>
                Learn the basics of the Croatian language through examples, word types, and everyday scenarios.
            </p>
            
            {/* 2x3 raspored kartica */}
            <div className={styles.cardGrid}>
                <div className={styles.card} onClick={() => navigate('/dictionary')}>
                    <div className={styles.cardHeader}>
                        <img src="/images/8.webp" alt="Dictionary Icon" className={styles.cardIcon} />
                    </div>
                    <h3 className={styles.cardTitle}>Dictionary</h3>
                </div>

                <div className={styles.card} onClick={() => navigate('/category-cards')}>
                    <div className={styles.cardHeader}>
                        <img src="/images/3.webp" alt="Words Example Icon" className={styles.cardIcon} />
                    </div>
                    <h3 className={styles.cardTitle}>Words</h3>
                </div>

                <div className={styles.card} onClick={() => navigate('/scenario-cards')}>
                    <div className={styles.cardHeader}>
                        <img src="/images/6.webp" alt="Scenarios Icon" className={styles.cardIcon} />
                    </div>
                    <h3 className={styles.cardTitle}>Every Day Scenarios</h3>
                </div>

                <div className={styles.card} onClick={() => navigate('/categories')}>
                    <div className={styles.cardHeader}>
                        <img src="/images/2.webp" alt="Categories Icon" className={styles.cardIcon} />
                    </div>
                    <h3 className={styles.cardTitle}>Categories</h3>
                </div>

                <div className={styles.card} onClick={() => navigate('/examples')}>
                    <div className={styles.cardHeader}>
                        <img src="/images/9.webp" alt="Examples Icon" className={styles.cardIcon} />
                    </div>
                    <h3 className={styles.cardTitle}>Examples</h3>
                </div>

                <div className={styles.card} onClick={() => navigate('/verb-tenses')}>
                    <div className={styles.cardHeader}>
                        <img src="/images/7.webp" alt="Examples Icon" className={styles.cardIcon} />
                    </div>
                    <h3 className={styles.cardTitle}>Verb Tenses</h3>
                </div>

                <div className={styles.card} onClick={() => navigate('/jobs')}>
                    <div className={styles.cardHeader}>
                        <img src="/images/1.webp" alt="Extra Icon" className={styles.cardIcon} />
                    </div>
                    <h3 className={styles.cardTitle}>Jobs</h3>
                </div>
            </div>
        </div>
    );
};

export default GeneralLanguage;
