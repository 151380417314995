// src/utils/speechUtils.js

export const speakText = (text, languageCode) => {
    if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance(text); // Kreiraj izgovor
        utterance.lang = languageCode; // Postavi jezik (npr. 'hr', 'en')

        // Postavi dodatne parametre po potrebi
        utterance.rate = 1;   // Brzina govora
        utterance.pitch = 1;  // Visina glasa

        // Pokreni govor
        window.speechSynthesis.speak(utterance);
    } else {
        console.error("Speech synthesis is not supported in this browser.");
    }
};
