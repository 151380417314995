import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';
import { fetchScenarios, fetchCategories } from '../services/api';
import { LanguageContext } from '../context/LanguageContext';
import styles from './ScenarioCards.module.css';  // Uvezi CSS module
import { speakText } from '../utils/speechUtils';  // Importiraj funkciju za govor

const ScenarioCards = () => {
    const { euLanguage, nonEuLanguage, croatianLanguage = 'hr' } = useContext(LanguageContext);
    const [categories, setCategories] = useState([]);
    const [scenarios, setScenarios] = useState({});
    const [activeCategory, setActiveCategory] = useState(null); // Trenutno aktivna kategorija
    const [isModalOpen, setIsModalOpen] = useState(false);

    Modal.setAppElement('#root');  // Prilagodi prema elementu u kojem se nalazi aplikacija (obično je #root u React aplikacijama)

    useEffect(() => {
        fetchCategories()
            .then(response => {
                const filteredCategories = response.data.filter(cat => cat.category_type === 'scenario');
                setCategories(filteredCategories);

                filteredCategories.forEach(category => {
                    fetchScenarios(category.category_en)
                        .then(response => {
                            setScenarios(prev => ({
                                ...prev,
                                [category.category_en]: response.data
                            }));
                        })
                        .catch(err => console.error(err));
                });
            })
            .catch(err => console.error(err));
    }, [croatianLanguage, euLanguage, nonEuLanguage]);

    const openModal = (categoryEn) => {
        if (!isModalOpen) {  // Provjeri je li modal već otvoren
            setActiveCategory(categoryEn);
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={styles.scenarioCardContainer}>
            {categories.map((category, index) => (
                <div key={index} className={styles.scenarioCard} onClick={() => openModal(category.category_en)}>
                    <div className={styles.imageContainer}>
                        <img src={`images/scenarios/${index + 1}.webp`} alt={category[`category_${croatianLanguage}`]} className={styles.scenarioImage} />
                        <h3 className={styles.scenarioCardTitle}>{category[`category_${croatianLanguage}`]}</h3>
                        <p className={styles.scenarioCardSubtitle}>
                            {category[`category_${euLanguage}`]} / {category[`category_${nonEuLanguage}`]}
                        </p>
                    </div>
                </div>
            ))}

            {activeCategory && (
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    className={styles.modalContent}
                    overlayClassName={styles.modalOverlay}
                >
                    {activeCategory ? (
                        <>
                            <div className={styles.modalHeader}>
                                {/* Naslov modala */}
                                <h2 className={styles.modalTitle}>
                                    {categories.find(cat => cat.category_en === activeCategory)[`category_${croatianLanguage}`]}
                                </h2>

                                {/* Prikaz prijevoda ispod naslova */}
                                <p className={styles.modalSubtitle}>
                                    {categories.find(cat => cat.category_en === activeCategory)[`category_${euLanguage}`]} / {categories.find(cat => cat.category_en === activeCategory)[`category_${nonEuLanguage}`]}
                                </p>

                                {/* Gumb za zatvaranje */}
                                <button onClick={closeModal} className={styles.closeButtonX}>
                                    &times;
                                </button>
                            </div>

                            <div className={styles.modalInnerContent}>
                                <table className={styles.wordTable}>
                                    <thead>
                                        <tr>
                                            <th>HR</th>
                                            <th>{euLanguage.toUpperCase()}</th>
                                            <th>{nonEuLanguage.toUpperCase()}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {scenarios[activeCategory] ? (
                                            scenarios[activeCategory].map((scenario, index) => (
                                                <tr key={index}>
                                                    {/* Klikom na rečenicu na HR ili EU jeziku pokreće se izgovor */}
                                                    <td onClick={() => speakText(scenario[`sentence_${croatianLanguage}`], 'hr')}>
                                                        {scenario[`sentence_${croatianLanguage}`]}
                                                    </td>

                                                    <td onClick={() => speakText(scenario[`sentence_${euLanguage}`], euLanguage)}>
                                                        {scenario[`sentence_${euLanguage}`] || 'N/A'}
                                                    </td>

                                                    <td onClick={() => speakText(scenario[`sentence_${nonEuLanguage}`], nonEuLanguage)}>
                                                    {scenario[`sentence_${nonEuLanguage}`] || 'N/A'}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3">Loading...</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <button onClick={closeModal} className={styles.closeButton}>
                                Close
                            </button>
                        </>
                    ) : (
                        <div>Loading...</div>
                    )}
                </Modal>
            )}
        </div>
    );
};

export default ScenarioCards;
