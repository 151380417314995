import React, { useEffect, useState, useContext } from 'react';
import { fetchScenarios, fetchCategories } from '../services/api';  // Dodaj API za kategorije i scenarije
import { LanguageContext } from '../context/LanguageContext'; // Dohvati jezike iz konteksta
import './Scenarios.css';  // Uvezi tvoj novi CSS

const Scenarios = () => {
    const { euLanguage, nonEuLanguage, croatianLanguage = 'hr' } = useContext(LanguageContext); // Dohvati jezike iz konteksta
    const [scenarios, setScenarios] = useState([]);
    const [categories, setCategories] = useState([]);  // Dodaj stanje za kategorije
    const [category, setCategory] = useState('');  // Kategorija za filtriranje

    // Dohvati scenarije kada se promijeni kategorija
    useEffect(() => {
        fetchScenarios(category)  // API poziv s odabranom kategorijom
            .then(response => {
                setScenarios(response.data);  // Postavi dohvaćene scenarije
            })
            .catch(err => console.error(err));
    }, [category]);

    // Dohvati kategorije tipa 'scenario'
    useEffect(() => {
        fetchCategories()  // Dohvati sve kategorije
            .then(response => {
                // Filtriraj samo one kategorije koje imaju 'category_type' kao 'scenario'
                const filteredCategories = response.data.filter(cat => cat.category_type === 'scenario');
                setCategories(filteredCategories);  // Postavi samo scenario kategorije
            })
            .catch(err => console.error(err));
    }, []);

    return (
        <div className="scenarios-container">
            <h1 className="scenarios-title">Scenarios</h1>

            {/* Padajući izbornik za filtriranje kategorija */}
            <div className="filter-container">
                <label htmlFor="category" className="filter-label">Filter by Category:</label>
                <select id="category" className="filter-select" onChange={(e) => setCategory(e.target.value)}>
                    <option value="">All Categories</option>
                    {categories.map((cat, index) => (
                        <option key={index} value={cat.category_en}>  {/* Koristi category_en kao value */}
                            {cat[`category_${croatianLanguage}`]} / {cat[`category_${euLanguage}`]} / {cat[`category_${nonEuLanguage}`]}
                        </option>
                    ))}
                </select>
            </div>

            {/* Prikaz tablice scenarija */}
            <table className="scenarios-table">
                <thead className="scenarios-table-header">
                    <tr>
                        <th>Scenario ({croatianLanguage.toUpperCase()})</th>
                        <th>Scenario ({euLanguage.toUpperCase()})</th>
                        <th>Scenario ({nonEuLanguage.toUpperCase()})</th>
                    </tr>
                </thead>
                <tbody className="scenarios-table-body">
                    {scenarios.length > 0 ? (  // Prikaži podatke samo ako ih ima
                        scenarios.map((scenario, index) => (
                            <tr key={index} className="scenarios-row">
                                <td className="scenario-cell">{scenario[`sentence_${croatianLanguage}`]}</td>
                                <td className="scenario-cell">{scenario[`sentence_${euLanguage}`] || 'N/A'}</td>
                                <td className="scenario-cell">{scenario[`sentence_${nonEuLanguage}`] || 'N/A'}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3" className="no-scenarios">No scenarios found for the selected category.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Scenarios;
