import React, { useEffect, useState, useContext, useMemo } from 'react';
import Modal from 'react-modal';
import { fetchDictionary, fetchCategories } from '../services/api';
import { LanguageContext } from '../context/LanguageContext';
import styles from './CategoryCards.module.css';  // Uvezi CSS module
import { speakText } from '../utils/speechUtils'; // Importiraj funkciju za govor

const CategoryCards = () => {
    const { euLanguage, nonEuLanguage, croatianLanguage = 'hr' } = useContext(LanguageContext);
    const [categories, setCategories] = useState([]);
    const [dictionary, setDictionary] = useState({});
    const [activeCategory, setActiveCategory] = useState(null); // Trenutno aktivna kategorija
    const [isModalOpen, setIsModalOpen] = useState(false);

    Modal.setAppElement('#root');  // Prilagodi prema elementu u kojem se nalazi aplikacija (obično je #root u React aplikacijama)

    // Koristi useMemo kako bi memorirao wordTypeToCategory
    const wordTypeToCategory = useMemo(() => ({
        NOUN: 'Nouns',
        PRONOUN: 'Pronouns',
        ADVERB: 'Adverbs',
        ADJECTIVE: 'Adjectives',
        VERB: 'Verbs',
        PREPOSITION: 'Prepositions',
        CONJUNCTION: 'Conjunctions',
        INTERJECTION: 'Interjections',
        NUMERAL: 'Numerals'
    }), []); // Prazna lista ovisnosti jer se ovo ne mijenja.

    useEffect(() => {
        fetchCategories()
            .then(response => {
                const filteredCategories = response.data.filter(cat =>
                    Object.values(wordTypeToCategory).includes(cat.category_en)
                );
                setCategories(filteredCategories);

                filteredCategories.forEach(category => {
                    const wordType = Object.keys(wordTypeToCategory).find(key => wordTypeToCategory[key] === category.category_en);
                    fetchDictionary(croatianLanguage, euLanguage, nonEuLanguage, wordType)
                        .then(response => {
                            setDictionary(prev => ({
                                ...prev,
                                [category.category_en]: response
                            }));
                        })
                        .catch(err => console.error(err));
                });
            })
            .catch(err => console.error(err));
    }, [croatianLanguage, euLanguage, nonEuLanguage, wordTypeToCategory]);

    const openModal = (categoryEn) => {
        if (!isModalOpen) {  // Provjeri je li modal već otvoren
            setActiveCategory(categoryEn);
            setIsModalOpen(true);
        }
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={styles.categoryCardContainer}>
            {categories.map((category, index) => (
                <div key={index} className={styles.categoryCard} onClick={() => openModal(category.category_en)}>
                    <div className={styles.imageContainer}>
                        <img src={`images/${index + 1}.webp`} alt={category[`category_${croatianLanguage}`]} className={styles.categoryImage} />
                        <h3 className={styles.categoryCardTitle}>{category[`category_${croatianLanguage}`]}</h3>
                        <p className={styles.categoryCardSubtitle}>
                            {category[`category_${euLanguage}`]} / {category[`category_${nonEuLanguage}`]}
                        </p>
                    </div>
                </div>
            ))}

            {activeCategory && (
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    className={styles.modalContent}
                    overlayClassName={styles.modalOverlay}
                >
                    {activeCategory ? (
                        <>
                            <div className={styles.modalHeader}>
                                {/* Naslov modala */}
                                <h2 className={styles.modalTitle}>
                                    {categories.find(cat => cat.category_en === activeCategory)[`category_${croatianLanguage}`]}
                                </h2>

                                {/* Prikaz prijevoda ispod naslova */}
                                <p className={styles.modalSubtitle}>
                                    {categories.find(cat => cat.category_en === activeCategory)[`category_${euLanguage}`]} / {categories.find(cat => cat.category_en === activeCategory)[`category_${nonEuLanguage}`]}
                                </p>

                                {/* Gumb za zatvaranje */}
                                <button onClick={closeModal} className={styles.closeButtonX}>
                                    &times;
                                </button>
                            </div>

                            <div className={styles.modalInnerContent}>
                                <table className={styles.wordTable}>
                                    <thead>
                                        <tr>
                                            <th>HR</th>
                                            <th>{euLanguage.toUpperCase()}</th>
                                            <th>{nonEuLanguage.toUpperCase()}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dictionary[activeCategory] ? (
                                            dictionary[activeCategory].map((word, wordIndex) => (
                                                <tr key={wordIndex}>
                                                    <td onClick={() => speakText(word.word_hr, 'hr')}>
                                                        {word.word_hr || 'N/A'}
                                                    </td>
                                                    <td onClick={() => speakText(word.translations.find(t => t.language_code === euLanguage)?.translated_word, euLanguage)}>
                                                        {word.translations.find(t => t.language_code === euLanguage)?.translated_word || 'N/A'}
                                                    </td>
                                                    <td onClick={() => speakText(word.translations.find(t => t.language_code === nonEuLanguage)?.translated_word, nonEuLanguage)}>
                                                        {word.translations.find(t => t.language_code === nonEuLanguage)?.translated_word || 'N/A'}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3">Loading...</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <button onClick={closeModal} className={styles.closeButton}>
                                Close
                            </button>
                        </>
                    ) : (
                        <div>Loading...</div>
                    )}
                </Modal>
            )}
        </div>
    );
};

export default CategoryCards;
