import React from 'react';
import { useNavigate } from 'react-router-dom';  // Import navigacije

const ProfessionalLanguage = () => {
    const navigate = useNavigate();  // Kreiramo navigaciju

    // Navigacija na stranicu Jobs
    const handleEnterJobs = () => {
        navigate('/jobs');  // Navigiraj na Jobs
    };

    return (
        <div className="professional-language-container">
            <h1>Professional Language Learning</h1>
            <p>Here you can improve your skills for specific job categories and scenarios.</p>

            {/* Gumb koji vodi na stranicu Jobs */}
            <button onClick={handleEnterJobs} className="professional-btn">
                Enter Professional Language for Jobs
            </button>
        </div>
    );
};

export default ProfessionalLanguage;
