import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSpecializedScenarios } from '../services/api';  // API poziv za dohvaćanje scenarija
import { LanguageContext } from '../context/LanguageContext';
import styles from './SpecializedScenarios.module.css';  // Uvozi module CSS
import { speakText } from '../utils/speechUtils';  // Importiraj funkciju za govor

const SpecializedScenarios = () => {
    const { jobId } = useParams();  // Dohvati ID posla iz URL-a
    const { euLanguage, nonEuLanguage, croatianLanguage = 'hr' } = useContext(LanguageContext);
    const [scenarios, setScenarios] = useState([]);  // State za scenarije
    const [jobName, setJobName] = useState('');  // Dodano za naziv posla
    const [selectedTranslation, setSelectedTranslation] = useState(null); // Drži prijevod za modal
    const [hoveredLanguage, setHoveredLanguage] = useState(null); // Dodano za praćenje hovera

    useEffect(() => {
        fetchSpecializedScenarios(jobId)
            .then(response => {
                setScenarios(response.data);  // Postavi dohvaćene scenarije
                
                // Ako scenariji postoje, postavi naziv posla
                if (response.data.length > 0) {
                    setJobName(response.data[0].job_name);  // Pretpostavljamo da API vraća job_name u podacima
                }
            })
            .catch(error => console.error('Error fetching specialized scenarios:', error));
    }, [jobId]);

    // Funkcija za otvaranje prijevoda u modalu
    const handleShowTranslation = (scenario) => {
        setSelectedTranslation(scenario);
    };

    // Funkcija za zatvaranje modala
    const handleCloseModal = () => {
        setSelectedTranslation(null);
    };

    return (
        <div className={styles.specializedScenariosContainer}>
            <h2>Specialized Scenarios for {jobName}</h2>

            {/* Prikaz slike posla ispod naslova */}
            <div className={styles.jobImageContainer}>
                <img
                    src={`/images/zanimanja/${jobName}.webp`}  // Putanja do slike posla
                    alt={jobName}
                    className={styles.jobImage}
                />
            </div>

            {/* Prikaz tablice s non-EU jezikom i gumbom za prijevod */}
            <table className={styles.scenariosTable}>
                <thead>
                    <tr>
                        <th>Scenario ({nonEuLanguage.toUpperCase()})</th>
                        <th>HR & {euLanguage.toUpperCase()}</th>
                    </tr>
                </thead>
                <tbody>
                    {scenarios.map((scenario, index) => (
                        <tr key={index}>
                            <td onClick={() => speakText(scenario[`sentence_${nonEuLanguage}`], nonEuLanguage)}>
  {scenario[`sentence_${nonEuLanguage}`] || 'N/A'}
</td>

                            <td>
                                {/* Izgovor rečenica na hrvatskom, EU i non-EU jeziku */}
                                <button
                                    className={styles.translateButton}
                                    onClick={() => handleShowTranslation(scenario)}
                                >
                                    Click
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal za prikaz prijevoda */}
            {selectedTranslation && (
                <div className={styles.modalOverlay} onClick={handleCloseModal}>
                    <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                        <img
                            src={`/images/zanimanja/${jobName}.webp`}  // Prikaz slike unutar modala
                            alt={jobName}
                            className={styles.modalImage}
                        />
                        <h3 className={styles.modalTitle}> {jobName}</h3>
                        {/* Izgovor unutar modalne komponente */}
                        <p>
                            <strong>{croatianLanguage.toUpperCase()} : </strong>
                            <span 
                                onMouseEnter={() => setHoveredLanguage('hr')}
                                onMouseLeave={() => setHoveredLanguage(null)}
                                onClick={() => speakText(selectedTranslation[`sentence_${croatianLanguage}`], 'hr')}
                                className={styles.speakerText}
                            >
                                {selectedTranslation[`sentence_${croatianLanguage}`]}
                                {hoveredLanguage === 'hr' && (
                                    <img src="/images/speaker-icon.webp" alt="Speaker" className={styles.speakerIcon} />
                                )}
                            </span>
                        </p>
                        <p>
                            <strong>{euLanguage.toUpperCase()} : </strong>
                            <span 
                                onMouseEnter={() => setHoveredLanguage(euLanguage)}
                                onMouseLeave={() => setHoveredLanguage(null)}
                                onClick={() => speakText(selectedTranslation[`sentence_${euLanguage}`], euLanguage)}
                                className={styles.speakerText}
                            >
                                {selectedTranslation[`sentence_${euLanguage}`] || 'N/A'}
                                {hoveredLanguage === euLanguage && (
                                    <img src="/images/speaker-icon.webp" alt="Speaker" className={styles.speakerIcon} />
                                )}
                            </span>
                        </p>
                        <p>
                            <strong>{nonEuLanguage.toUpperCase()} : </strong>
                            <span 
                                onMouseEnter={() => setHoveredLanguage(nonEuLanguage)}
                                onMouseLeave={() => setHoveredLanguage(null)}
                                onClick={() => speakText(selectedTranslation[`sentence_${nonEuLanguage}`], nonEuLanguage)}
                                className={styles.speakerText}
                            >
                                {selectedTranslation[`sentence_${nonEuLanguage}`] || 'N/A'}
                                {hoveredLanguage === nonEuLanguage && (
                                    <img src="/images/speaker-icon.webp" alt="Speaker" className={styles.speakerIcon} />
                                )}
                            </span>
                        </p>
                        <button className={styles.closeButton} onClick={handleCloseModal}>Zatvori</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SpecializedScenarios;
