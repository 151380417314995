import React, { createContext, useState, useEffect } from 'react';

// Kreiramo kontekst jezika
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    // Dohvati jezike iz localStorage ili postavi defaultne
    const [euLanguage, setEuLanguage] = useState(localStorage.getItem('euLanguage') || 'en');
    const [nonEuLanguage, setNonEuLanguage] = useState(localStorage.getItem('nonEuLanguage') || 'ar');

    // Kad se jezici promijene, spremi ih u localStorage
    useEffect(() => {
        localStorage.setItem('euLanguage', euLanguage);
        localStorage.setItem('nonEuLanguage', nonEuLanguage);
    }, [euLanguage, nonEuLanguage]);

    return (
        <LanguageContext.Provider value={{ euLanguage, setEuLanguage, nonEuLanguage, setNonEuLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
