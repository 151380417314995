import React, { useEffect, useState, useContext } from 'react';
import { fetchDictionary, fetchCategories } from '../services/api';
import { LanguageContext } from '../context/LanguageContext';
import './Wordsexample.css';

const Wordsexample = () => {
    const { euLanguage, nonEuLanguage, croatianLanguage = 'hr' } = useContext(LanguageContext);
    const [categories, setCategories] = useState([]);
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dictionary, setDictionary] = useState([]);

    const wordTypeToCategory = {
        NOUN: 'Nouns',
        PRONOUN: 'Pronouns',
        ADVERB: 'Adverbs',
        ADJECTIVE: 'Adjectives',
        VERB: 'Verbs',
        PREPOSITION: 'Prepositions',
        CONJUNCTION: 'Conjunctions',
        INTERJECTION: 'Interjections',
        NUMERAL: 'Numerals'
    };

    useEffect(() => {
        fetchCategories()
            .then(response => {
                const filteredCategories = response.data.filter(cat =>
                    Object.values(wordTypeToCategory).includes(cat.category_en)
                );
                setCategories(filteredCategories);
            })
            .catch(err => console.error(err));
    }, []);

    const handleCategoryClick = (category) => {
        if (expandedCategory === category) {
            setExpandedCategory(null);
        } else {
            setLoading(true);
            setExpandedCategory(category);

            const wordType = Object.keys(wordTypeToCategory).find(key => wordTypeToCategory[key] === category);

            fetchDictionary(croatianLanguage, euLanguage, nonEuLanguage, wordType)
                .then(response => {
                    setDictionary(response);
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                });
        }
    };

    return (
        <div className="wordsexample-container">
            <h1 className="wordsexample-title">Wordsexample</h1>

            {categories.map((category, index) => (
                <div key={index} className="category-section">
                    <h2 
                        className="category-title" 
                        onClick={() => handleCategoryClick(category.category_en)}
                    >
                        {category[`category_${croatianLanguage}`]} / {category[`category_${euLanguage}`]} / {category[`category_${nonEuLanguage}`]}
                    </h2>

                    {expandedCategory === category.category_en && (
                        <div className="word-list">
                            {loading ? (
                                <p>Loading...</p>
                            ) : (
                                <table className="word-table">
                                    <thead>
                                        <tr>
                                            <th>HR</th>
                                            <th>{euLanguage.toUpperCase()}</th>
                                            <th>{nonEuLanguage.toUpperCase()}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dictionary.length > 0 ? (
                                            dictionary.map((word, index) => (
                                                <tr key={index}>
                                                    <td>{word.word_hr || 'N/A'}</td>
                                                    <td>{word.translations.find(t => t.language_code === euLanguage)?.translated_word || 'N/A'}</td>
                                                    <td>{word.translations.find(t => t.language_code === nonEuLanguage)?.translated_word || 'N/A'}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3">No words found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Wordsexample;
