import React, { useEffect, useState, useContext } from 'react';
import { fetchJobs } from '../services/api';  // API poziv za poslove
import { LanguageContext } from '../context/LanguageContext'; // Kontekst jezika
import { Link } from 'react-router-dom';  // Koristi Link za navigaciju
import './Jobs.css';  // CSS za stiliziranje

const Jobs = () => {
    const { euLanguage, nonEuLanguage, croatianLanguage = 'hr' } = useContext(LanguageContext);  // Dohvati jezike iz konteksta
    const [jobs, setJobs] = useState([]);
    const [category, setCategory] = useState('');  // Dodaj filter kategorije

    // Dohvati poslove kad se komponenta mounta ili kad se promijeni kategorija
    useEffect(() => {
        fetchJobs(category)  // API poziv za dohvaćanje poslova
            .then(response => {
                setJobs(response.data);  // Postavi dohvaćene poslove
            })
            .catch(err => console.error(err));
    }, [category]);  // Ponovno dohvaćanje podataka kad se promijeni kategorija

    // Grupiranje poslova po kategorijama
    const groupedJobs = jobs.reduce((grouped, job) => {
        const jobCategory = job.category;
        if (!grouped[jobCategory]) {
            grouped[jobCategory] = [];
        }
        grouped[jobCategory].push(job);
        return grouped;
    }, {});

    return (
        <div className="jobs-container">
            <h1 className="jobs-title">Jobs by Category</h1>

            {/* Padajući izbornik za filtriranje poslova prema kategorijama */}
            <div className="filter-container">
                <label htmlFor="category" className="filter-label">Filter by Category:</label>
                <select id="category" className="filter-select" onChange={(e) => setCategory(e.target.value)}>
                    <option value="">All Categories</option>
                    {Object.keys(groupedJobs).map((cat, index) => (
                        <option key={index} value={cat}>{cat}</option>
                    ))}
                </select>
            </div>

            {/* Prikaz poslova grupiranih po kategorijama */}
            {Object.keys(groupedJobs).map((jobCategory, index) => (
                <div key={index} className="job-category">
                    <h2 className="category-title">{jobCategory}</h2>
                    <table className="jobs-table">
                        <thead className="jobs-table-header">
                            <tr>
                                <th>Job ({croatianLanguage.toUpperCase()})</th>
                                <th>Job ({euLanguage.toUpperCase()})</th>
                                <th>Job ({nonEuLanguage.toUpperCase()})</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody className="jobs-table-body">
                            {groupedJobs[jobCategory].map((job, jobIndex) => (
                                <tr key={jobIndex} className="job-row">
                                    <td className="job-cell">{job[`job_${croatianLanguage}`]}</td>
                                    <td className="job-cell">{job[`job_${euLanguage}`] || 'N/A'}</td>
                                    <td className="job-cell">{job[`job_${nonEuLanguage}`] || 'N/A'}</td>
                                    <td className="job-cell actions-cell">
    {/* Dodaj gumb za specijalizirani rječnik */}
    <Link to={`/jobs/specialized-dictionary/${job.id}`} className="btn-action">Dictionary</Link>
    {/* Dodaj gumb za scenarije */}
    <Link to={`/jobs/specialized-scenarios/${job.id}`} className="btn-action">Scenarios</Link>
</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
};

export default Jobs;
