import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MainPage.module.css';  // Uvezi CSS za stilizaciju

const MainPage = () => {
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        navigate(path);
    };

    return (
        <div className={styles.mainPageContainer}>
            <h1 className={styles.title}>DOBRODOŠLI !</h1>

            <div className={styles.learningSection}>
                <h2>General Language Learning</h2>
                <p>Learn the basics of the Croatian language through examples, word types, and everyday scenarios.</p>
                <button onClick={() => handleNavigate('/general-language')}>
                    Enter General
                </button>
            </div>

            <div className={styles.learningSection}>
                <h2>Professional Language for Jobs</h2>
                <p>Improve your Croatian skills related to specific jobs, scenarios, and professional vocabulary.</p>
                <button onClick={() => handleNavigate('/test-jobs')}>
                    Enter Professional
                </button>
            </div>

            <button className={styles.backToHome} onClick={() => window.location.href = '/'}>
                Change language
            </button>
        </div>
    );
};

export default MainPage;
