import React, { useEffect, useState, useContext } from 'react';
import { fetchJobs } from '../services/api';  // API poziv za poslove
import { LanguageContext } from '../context/LanguageContext'; // Kontekst jezika
import { Link } from 'react-router-dom';  // Koristi Link za navigaciju
import styles from './Testjobs.module.css';  // Uvoz CSS modula
import { useLocation } from 'react-router-dom';  // Ovo dodaj na vrh datoteke


const Testjobs = () => {
    const { euLanguage, nonEuLanguage, croatianLanguage = 'hr' } = useContext(LanguageContext);  // Dohvati jezike iz konteksta
    const [jobs, setJobs] = useState([]);
    const [category, setCategory] = useState('');  // Dodaj filter kategorije
    const location = useLocation();

    useEffect(() => {
        // Kada smo na /test-jobs, dodaj klasu za pozadinu
        if (location.pathname === '/test-jobs') {
            document.body.classList.add('test-jobs-page');
        }

        // Ukloni klasu kad se komponenta unmount-a
        return () => {
            document.body.classList.remove('test-jobs-page');
        };
    }, [location]);
    
    
    
    // Dohvati poslove kad se komponenta mounta ili kad se promijeni kategorija
    useEffect(() => {
             fetchJobs(category)  // API poziv za dohvaćanje poslova
            .then(response => {
                setJobs(response.data);  // Postavi dohvaćene poslove
            })
            .catch(err => console.error(err));
    }, [category]);  // Ponovno dohvaćanje podataka kad se promijeni kategorija

    // Grupiranje poslova po kategorijama
    const groupedJobs = jobs.reduce((grouped, job) => {
        const jobCategory = job.category;
        if (!grouped[jobCategory]) {
            grouped[jobCategory] = [];
        }
        grouped[jobCategory].push(job);
        return grouped;
    }, {});

    return (
        <div className={styles['jobs-container']}>
    <div className={styles['jobs-grid']}>
        {Object.keys(groupedJobs).map((jobCategory, index) => (
            <div key={index} className={styles['job-category']}>
                <h2 className={styles['category-title']}>{jobCategory}</h2>
                <div className={styles['jobs-list']}>
                    {groupedJobs[jobCategory].map((job, jobIndex) => (
                        <div key={jobIndex} className={styles['job-card']}>
                            <div className={styles['job-image-container']}>
                                <img
                                    src={`/images/zanimanja/${job.job_en}.webp`}
                                    alt={job[`job_${croatianLanguage}`]}
                                    className={styles['job-image']}
                                />
                            </div>
                            <div className={styles['job-info']}>
                                <h3 className={styles['job-title']}>{job[`job_${croatianLanguage}`]}</h3>
                                <p className={styles['job-subtitle']}>
                                    {job[`job_${euLanguage}`] || 'N/A'} / {job[`job_${nonEuLanguage}`] || 'N/A'}
                                </p>
                                <div className={styles['actions-cell']}>
                                    <Link to={`/jobs/specialized-dictionary/${job.id}`} className={styles['btn-action']}>
                                        Dictionary
                                    </Link>
                                    <Link to={`/jobs/specialized-scenarios/${job.id}`} className={styles['btn-action']}>
                                        Scenarios
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ))}
    </div>
</div>

    );
};

export default Testjobs;
