import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import LanguageSelector from './LanguageSelector';
import './Homepage.css';  // Uvezi tvoj CSS

const Homepage = () => {
    const { setEuLanguage, setNonEuLanguage } = useContext(LanguageContext);

    const [selectedEuLanguage, setSelectedEuLanguage] = useState('en');
    const [selectedNonEuLanguage, setSelectedNonEuLanguage] = useState('ar');
    const navigate = useNavigate();

    const handleContinue = () => {
        if (selectedEuLanguage && selectedNonEuLanguage) {
            setEuLanguage(selectedEuLanguage);
            setNonEuLanguage(selectedNonEuLanguage);
            navigate('/main-page');  // Navigiraj na Main Page umjesto na Main Menu
        } else {
            alert('Please select both languages!');
        }
    };
    

    return (
        <div className="homepage-container">
            <div className="content-wrapper"> {/* Dodaj novi div za sadržaj */}
            <h1 className="homepage-title">Welcome to the Croatian Language Learning App!</h1>

            <p className="homepage-paragraph">
        Select a known EU language to assist you in learning Croatian, and your native language:
    </p>
    
                <div>
                    <label>EU language:</label>
                    <LanguageSelector 
                        onSelectLanguage={setSelectedEuLanguage} 
                        defaultLanguage="en"
                        selectedLanguage={selectedEuLanguage}
                        filterByRegion="EU"
                    />
                </div>
    
                <div>
                    <label>Your language:</label>
                    <LanguageSelector 
                        onSelectLanguage={setSelectedNonEuLanguage} 
                        defaultLanguage="ar"
                        selectedLanguage={selectedNonEuLanguage}
                        filterByRegion="non-EU"
                    />
                </div>
    
                <button onClick={handleContinue}>Continue</button>
            </div> {/* Zatvaranje novog diva */}
        </div>
    );
    
};

export default Homepage;
