import React, { useEffect, useState, useContext } from 'react'; 
import { fetchCategoriesByType } from '../services/api';  // Dodaj API za dohvaćanje kategorija prema tipu
import { LanguageContext } from '../context/LanguageContext'; // Dodaj uvoz LanguageContexta
import styles from './Categories.module.css'; // Uvoz modularnog CSS-a

const Categories = () => {
    const { euLanguage, nonEuLanguage, croatianLanguage = 'hr' } = useContext(LanguageContext); // Dohvati jezike iz konteksta
    const [exampleCategories, setExampleCategories] = useState([]);  // Držimo kategorije za primjere
    const [scenarioCategories, setScenarioCategories] = useState([]);  // Držimo kategorije za scenarije
    const [tensesCategories, setTensesCategories] = useState([]); // Držimo kategorije za tenses (vremena)

    // Dohvati kategorije za primjere, scenarije i tenses kada se komponenta mounta
    useEffect(() => {
        fetchCategoriesByType('example')  // Dohvati kategorije s tipom "example"
            .then(response => {
                setExampleCategories(response.data);
            })
            .catch(err => console.error(err));

        fetchCategoriesByType('scenario')  // Dohvati kategorije s tipom "scenario"
            .then(response => {
                setScenarioCategories(response.data);
            })
            .catch(err => console.error(err));

        fetchCategoriesByType('tenses')  // Dohvati kategorije s tipom "tenses"
            .then(response => {
                setTensesCategories(response.data);
            })
            .catch(err => console.error(err));
    }, []);

    return (
        <div className={styles.categoriesContainer}>
            <h1 className={styles.categoriesTitle}>Categories</h1>
            <div className={styles.categoriesGrid}>
                {/* Sekcija za Primjere */}
                <div className={styles.categorySection}>
                    <h2 className={styles.categoryTitle}>Types of Words</h2>
                    <table className={styles.table}>
                        <thead className={styles.tableHead}>
                            <tr>
                                <th className={styles.tableHeadCell}>{croatianLanguage.toUpperCase()}</th>
                                <th className={styles.tableHeadCell}>{euLanguage.toUpperCase()}</th>
                                <th className={styles.tableHeadCell}>{nonEuLanguage.toUpperCase()}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {exampleCategories.map((category, index) => (
                                <tr key={index} className={styles.tableRow}>
                                    <td className={styles.tableCell}>{category[`category_${croatianLanguage}`]}</td>
                                    <td className={styles.tableCell}>{category[`category_${euLanguage}`] || 'N/A'}</td>
                                    <td className={styles.tableCell}>{category[`category_${nonEuLanguage}`] || 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Sekcija za Scenarije */}
                <div className={styles.categorySection}>
                    <h2 className={styles.categoryTitle}>Everyday Scenarios</h2>
                    <table className={styles.table}>
                        <thead className={styles.tableHead}>
                            <tr>
                                <th className={styles.tableHeadCell}>{croatianLanguage.toUpperCase()}</th>
                                <th className={styles.tableHeadCell}>{euLanguage.toUpperCase()}</th>
                                <th className={styles.tableHeadCell}>{nonEuLanguage.toUpperCase()}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {scenarioCategories.map((category, index) => (
                                <tr key={index} className={styles.tableRow}>
                                    <td className={styles.tableCell}>{category[`category_${croatianLanguage}`]}</td>
                                    <td className={styles.tableCell}>{category[`category_${euLanguage}`] || 'N/A'}</td>
                                    <td className={styles.tableCell}>{category[`category_${nonEuLanguage}`] || 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Sekcija za Tenses */}
                <div className={styles.categorySection}>
                    <h2 className={styles.categoryTitle}>Verb Tenses</h2>
                    <table className={styles.table}>
                        <thead className={styles.tableHead}>
                            <tr>
                                <th className={styles.tableHeadCell}>{croatianLanguage.toUpperCase()}</th>
                                <th className={styles.tableHeadCell}>{euLanguage.toUpperCase()}</th>
                                <th className={styles.tableHeadCell}>{nonEuLanguage.toUpperCase()}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tensesCategories.map((category, index) => (
                                <tr key={index} className={styles.tableRow}>
                                    <td className={styles.tableCell}>{category[`category_${croatianLanguage}`]}</td>
                                    <td className={styles.tableCell}>{category[`category_${euLanguage}`] || 'N/A'}</td>
                                    <td className={styles.tableCell}>{category[`category_${nonEuLanguage}`] || 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Categories;
