import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL; // Dohvati API URL iz .env datoteke


// ********************* JEZICI *********************
// Dohvati sve jezike
export const fetchLanguages = () => {
    return axios.get(`${API_URL}/languages`);
};

// ********************* RJEČNIK *********************
// Dohvati rječnik prema odabranim jezicima i tipu riječi (opcionalno)
export const fetchDictionary = (hrLanguage, euLanguage, nonEuLanguage, wordType = 'All') => {
    return axios.get(`${API_URL}/words`, {
        params: {
            hr: hrLanguage,
            eu: euLanguage,
            nonEu: nonEuLanguage,
            wordType
        }
    })
    .then(response => {
        console.log('API Response:', response.data);
        return response.data;
    })
    .catch(error => {
        console.error('API Error:', error);
    });
};

// ********************* KATEGORIJE *********************
// Dohvati sve kategorije
export const fetchCategories = () => {
    return axios.get(`${API_URL}/categories`);
};

// Dohvati kategorije za određeni tip (npr. "example" ili "scenario")
export const fetchCategoriesByType = (categoryType) => {
    return axios.get(`${API_URL}/categories`, {
        params: { type: categoryType }
    });
};

// ********************* PRIMJERI *********************
// Dohvati primjere (s opcionalnim filterom prema kategoriji)
export const fetchExamples = (category = '') => {
    return axios.get(`${API_URL}/examples`, {
        params: { category }
    });
};

// ********************* SCENARIJI *********************
// Dohvati scenarije (s opcionalnim filterom prema kategoriji)
export const fetchScenarios = (category = '') => {
    return axios.get(`${API_URL}/scenarios`, {
        params: { category }
    });
};

// ********************* POSLOVI *********************
// Dohvati sve poslove (s opcionalnim filterom prema kategoriji)
export const fetchJobs = (category = '') => {
    const query = category ? `?category=${category}` : '';
    return axios.get(`${API_URL}/jobs${query}`);
};

// ********************* SPECIJALIZIRANI SADRŽAJ *********************
// Dohvati specijalizirani rječnik za određeno zanimanje
export const fetchSpecializedDictionary = (jobId) => {
    return axios.get(`${API_URL}/specialized-dictionaries`, {
        params: { job_id: jobId }
    });
};

// Dohvati specijalizirane scenarije za određeno zanimanje
export const fetchSpecializedScenarios = (jobId) => {
    return axios.get(`${API_URL}/specialized-scenarios`, {
        params: { job_id: jobId }
    });
};

// ********************* VRSTE RIJEČI *********************
// Dohvati sve vrste riječi
export const fetchWordTypes = () => {
    return axios.get(`${API_URL}/word-types`);
};

// Dohvati kategorije riječi
export const fetchWordCategories = () => {
    return axios.get(`${API_URL}/word-categories`);
};

// Dohvati konjugacije prema word_id
export const fetchVerbConjugations = (wordId) => {
    return axios.get(`${API_URL}/verb-conjugations/${wordId}`);
};
